import * as actionTypes from "./reducerTypes";
import { updateOrAppend } from "../helper";

export const defaultStateMap = {
  "map": undefined,
  "polys": [],
  "lines": [],
  "center": { lat: 52.392, lng: 8.938 },
  "size": 600,
  "zoom": 6,
  "useLetters": false,
  "fillAreas": true,
  "addressSearch": "",
  "showAvatars": false
};

export const reducerMap = (state, action) => {

  switch (action.type) {
    case actionTypes.SET_MAP:
      return {
        ...state,
        map: action.payload,
      };

    case actionTypes.CLEAR_MAP:
      return {
        ...state,
        polys: [],
        lines: [],
      };

    case actionTypes.IMPORT_DATA:
      return {
        ...state,
        polys: [],
        lines: [],
        center: action.payload.center || state.center,
        size: action.payload.size || state.size,
        zoom: action.payload.zoom || state.zoom,
        addressSearch: action.payload.addressSearch || state.addressSearch,
        useLetters: action.payload.useLetters,
        fillAreas: action.payload.fillAreas,
      };

    case actionTypes.SET_ADDRESS:
      return {
        ...state,
        addressSearch: action.payload,
      };

    case actionTypes.SHOW_AVATARS:
      return {
        ...state,
        showAvatars: action.payload,
      };

    case actionTypes.SET_POLYS:
      return {
        ...state,
        polys: action.payload,
      };

    case actionTypes.ADD_POLY:
      return {
        ...state,
        polys: [...state.polys, action.payload],
      };

    case actionTypes.UPDATE_POLY:
      return {
        ...state,
        polys: updateOrAppend(state.polys, action.payload),
        lines: []
      };


    case actionTypes.SET_LINES:
      return {
        ...state,
        lines: action.payload,
      };

    case actionTypes.SET_VIEW_BOUNDS:

      return {
        ...state,
        zoom: action.payload.zoom,
        center: action.payload.center,
      };


    case actionTypes.SET_LABEL_TYPE:
      return {
        ...state,
        useLetters: action.payload,
      };

    case actionTypes.SET_FILL_AREAS:
      return {
        ...state,
        fillAreas: action.payload,
      };

    default:
      throw new Error(`No matching action type: ${ action.type }`);
  }

};
