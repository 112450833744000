export const SET_MAP = "SET_MAP";
export const CLEAR_MAP = "CLEAR_MAP";
export const IMPORT_DATA = "IMPORT_DATA";
export const SET_ADDRESS = "SET_ADDRESS";
export const SHOW_AVATARS = "SHOW_AVATARS";

export const SET_POLYS = "SET_POLYS";
export const ADD_POLY = "ADD_POLY";
export const UPDATE_POLY = "UPDATE_POLY";

export const SET_LINES = "SET_LINES";
export const SET_VIEW_BOUNDS = "SET_VIEW_BOUNDS";
export const SET_LABEL_TYPE = "SET_LABEL_TYPE";
export const SET_FILL_AREAS = "SET_FILL_AREAS";
