import React from 'react';
import App from './App';
import ReactDOM from 'react-dom';
import "./style.scss";

const app = (
   <App />
)

ReactDOM.render(app, document.getElementById('root'));

