import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Modal } from "react-bootstrap";
import { ModalContext } from "./modalContext";

import { useForm } from "react-hook-form";
import { customStringify, saveAsFile } from "../helper";
import { showErrorBar, showSuccessBar } from "../ui/Snackbar";

const ExportImportModal = ({ callBack, enqueueSnackbar }) => {

  const [show, setShow] = useContext(ModalContext);
  const [exportData, setExportData] = useState("");
  const { register, handleSubmit, } = useForm();


  useEffect(() => {
    if (show.modalExportImport) {
      customStringify(show.state).then(result => {
        setExportData(result);
      });
    }
  }, [show.modalExportImport]);

  const onSubmit = (data) => {
    handleClose();
    callBack(data.import);
  };

  const handleClose = () => {
    setShow((show) => ( { ...show, modalExportImport: false } ));
  };

  const handleFocus = (event) => event.target.select();


  function readFromFile(e) {
    let input = document.getElementById(e.target.name);
    let fReader = new FileReader();
    fReader.readAsDataURL(input.files[0]);
    fReader.onloadend = function (event) {
      if (event.target.result.substring(0, 29) === "data:application/json;base64,") {
        handleClose();
        showSuccessBar(enqueueSnackbar, "Datei erfolgreich eingelesen!");
        callBack(atob(event.target.result.substring(29)));
      } else {
        showErrorBar(enqueueSnackbar, "Ungültige Datei! Bitte eine *.json-Datei auswählen!");
      }
    };
  }

  return (
    <Form>
      <Modal show={ show.modalExportImport } onHide={ handleClose }>
        <Modal.Header>
          <Modal.Title>Export/Import</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Form.Group controlId="formName">
            <Form.Label>Export</Form.Label>
            <Form.Control type="text"
                          value={ exportData }
                          readOnly={ true }
                          onFocus={ handleFocus }/>
          </Form.Group>
          <Button variant={ "success" }
                  className={ "mt-2 mb-5 me-5" }
                  onClick={ () => saveAsFile(exportData) }>Als Datei speichern</Button>

          <Form.Group controlId="formName">
            <Form.Label>Import</Form.Label>
            <Form.Control type="text"
                          placeholder="Flächen importieren..."
                          { ...register("import") } />
          </Form.Group>

          <Form.Group controlId="formLoadFile">
            <Form.Control type="file"
                          name={ "formLoadFile" }
                          className={ "mt-2 mb-5" }
                          onChange={ readFromFile }/>
          </Form.Group>

        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={ handleClose }>Close</Button>
          <Button variant="success" onClick={ handleSubmit(onSubmit) }>Import</Button>
        </Modal.Footer>
      </Modal>
    </Form>
  );
};

export default ExportImportModal;
