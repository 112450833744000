import React from "react";
import PropTypes from "prop-types";
import LoadingOverlay from "react-loading-overlay";

const LoadingScreen = props => (
    <LoadingOverlay
        {...props}
        spinner
        styles={{
            overlay: base => ({
                ...base,
                // background: "rgba(255,255,255,0.7)"
            }),
            content: base => ({
                ...base,
                marginTop: props.marginTop,
                position: "fixed",
                left: "44%",
                top: 250
            })
        }}
    >
        { props.children }
    </LoadingOverlay>
);

LoadingScreen.propTypes = {
    loading: PropTypes.bool
};

export default LoadingScreen;