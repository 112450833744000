import React from 'react';
import { DrawingManager, GoogleMap, Polyline, useJsApiLoader } from '@react-google-maps/api';
import { containerStyle, libs } from "../helper";

export const dataOptions = {
  drawingControl: true,
  drawingControlOptions: {
    drawingModes: ['polygon'],
  },

  polygonOptions: {
    fillOpacity: 0.5,
    clickable: true,
    editable: true,
    draggable: true,
    fillColor: "#4CBDC6",
    strokeColor: "#18769E",
    strokeOpacity: 1,
    strokeWeight: 3,
    zIndex: 2,
  },
};

function CustomMapView(props) {

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY,
    libraries: libs,
  });

  const options = {
    mapTypeId: "satellite",
    disableDefaultUI: true,
    zoomControl: true,
    mapTypeControl: true,
    scaleControl: true,
    rotateControl: true,
    rotateControlOptions: {
      position: 12
    },
    panControl: true,
  };

  return isLoaded ? (
    <>
      <GoogleMap
        options={ options }
        mapContainerStyle={ containerStyle }
        center={ props.center }
        zoom={ props.zoom }
        onLoad={ props.onLoad }
        onUnmount={ props.onUnmount }
      >

        <DrawingManager
          options={ dataOptions }
          drawingMode={ window.google.maps.drawing.OverlayType.POLYGON }
          onPolygonComplete={ poly => props.creationHandler(poly) }
          drawingControlOptions={ {
            position: window.google.maps.ControlPosition.BOTTOM_CENTER
          } }
        />

        { props.lines &&
        <Polyline
          path={ props.lines }
          options={ {
            strokeOpacity: 0.5,
            clickable: false,
            editable: false,
            draggable: false,
            strokeColor: "#1794ff",
            strokeWeight: 20,
            zIndex: 3,
          } }
        />
        }

        <div className={ "mapContainer" }>
          <div className={ "mapCenter" }>
            +
          </div>
        </div>

      </GoogleMap>
    </>

  ) : <></>;
}

export default React.memo(CustomMapView);
