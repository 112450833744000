import { Accordion } from "react-bootstrap";
import React from "react";

const AccordionContainer = ({ title, children, eventKey = "0" }) => {
  return (
    <Accordion className="col-* mt-5 mb-5" defaultActiveKey="0">
      <Accordion.Item eventKey={ eventKey }>
        <Accordion.Header>{ title }</Accordion.Header>
        <Accordion.Body as="div">
          { children }
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default AccordionContainer;
