import React from "react";

class AddressSearchBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: "Solutiance Services GmbH" };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleSubmit(event) {
    this.props.changeListener(this.state.value);
    this.props.callbackAddress(this.state.value);
    event.preventDefault();
  }

  render() {
    return (
      <form onSubmit={ this.handleSubmit }>
        <input type="text" value={ this.state.value } className={ "w-25 m-2" } onChange={ this.handleChange }/>
        <input type="submit" value="Submit"/>
      </form>
    );
  }
}

export default AddressSearchBox;
