import React, { useEffect, useRef, useState } from "react";
import BoxContainer from "../ui/BoxContainer";
import AccordionContainer from "../ui/AccordionContainer";
import { Button, Col, Row } from "react-bootstrap";
import CanvasMapView from "./CanvasMapView";
import { canvasPolygonOptions, getLabel } from "../helper";
import useDynamicRefs from "use-dynamic-refs";
import * as ReactDOM from "react-dom";
import { toPng } from 'html-to-image';
import LoadingScreen from "../ui/LoadingScreen";

const AvatarTuningView = ({ onAccept, onCancel, rawPolys }) => {

  const mapAllRef = useRef();
  const [getRef, setRef] = useDynamicRefs();
  const [polys, setPolys] = useState([]);
  const [sendingData, setSendingData] = useState(false);
  const widthMainContainer = 600

  useEffect(() => {
    let polys = rawPolys.map((rawPoly) => {

      let poly = new window.google.maps.Polygon({
        ...canvasPolygonOptions,
        paths: rawPoly.getPath(),
        center: rawPoly.center,
      });
      poly.m_id = rawPoly.m_id;
      poly.marker_position = rawPoly.center_marker.getPosition();
      poly.custom_pos = rawPoly.custom_pos

      return poly;
    });

    setPolys(polys);

  }, []);


  function filterElement(node) {
    const exclusionClasses = ["gmnoprint", "gm-style-cc"];
    return !exclusionClasses.some((classname) => node.classList?.contains(classname));
  }

  async function onAcceptAvatars() {
    setSendingData(true);

    let data = [];

    for (const poly of polys) {
      let ref = getRef(`map-${ poly.m_id }`).current;
      let points = poly.points.map((coord) => { return { "lat": coord.lat(), "lng": coord.lng() } })

      await toPng(ReactDOM.findDOMNode(ref), {
        cacheBust: true,
        includeQueryParams: true,
        filter: filterElement
      }).then((dataUrl) => {

        data.push({
          "id": poly.m_id,
          "label": getLabel(poly.m_id),
          "points": points,
          "center": poly.center_marker.getPosition(),
          "custom_pos": poly.custom_pos,
          "area": poly.area,
          "zoom": ref.state.map.zoom,
          "image": dataUrl,
        });
      });

      await new Promise(r => setTimeout(r, 100));
    }

    await toPng(ReactDOM.findDOMNode(mapAllRef.current), {
      cacheBust: true,
      includeQueryParams: true,
      filter: filterElement
    }).then((dataUrl) => {
      onAccept({
        "data": data,
        "image": dataUrl,
      });
    });

    setSendingData(false);

  }

  const markerMoveListener = (poly, position) => {
    let ref = getRef(`map-${ poly.m_id }`).current;
    let marker = ref.state.map["polys"][0].center_marker
    poly.custom_pos = true
    marker.setPosition(position)
  }

  return (
    <LoadingScreen active={ sendingData }
                   text="Avatare werden erzeugt..." >
      <AccordionContainer title={ "Erklärung" } eventKey={ "1" }>
        <Row>
          <Col>
            <p>
              Hier finden Sie die Zusammenfassung des erstellten Standorts.
            </p>
            <p>
              In der Gesamtübersicht können Sie das Übersichtsbild so zentrieren, dass alle Dächer in der
              Übersicht zu sehen sind. Außerdem können die Positionen der Marker der einzelnen Dächer
              verschoben werden. Die Position der Marker wird in den Einzelübersichten der Dachflächen
              übernommen.
            </p>
            <p>
              In der Einzelübersicht werden die Avatarbilder der Dächer angezeigt. Diese werden später als
              Avatarbild im Facility Scanner übernommen. Hier können die einzelnen Dachflächen so
              zentriert ins Bild gezogen werden, dass im Bild klar ist, um welche Dachfläche es sich
              handelt.
            </p>
            <p>
              Auch können hier die Marker der einzelnen Dächer verschoben werden und die Position passt
              sich dementsprechend in der Gesamtübersicht an.
            </p>
            <p>
              Das Gesamtübersichtsbild sowie die einzelnen Bilder der Dächer finden sich nach Hochladen
              der Datei im Facility Scanner wieder.
            </p>
          </Col>
        </Row>
      </AccordionContainer>

      { polys.length > 0 && (
        <>
          <BoxContainer title={ "Gesamtüberblick" }>
            <Row>
              <Col style={{ minWidth: widthMainContainer }}>
                <CanvasMapView polys={ polys }
                               markerMoveListener = { markerMoveListener }
                               reference={ mapAllRef }
                               size={ widthMainContainer }
                               padding={ 20 } />
              </Col>
            </Row>
          </BoxContainer>

          <BoxContainer title={ "Einzelübersicht" } >
            <Row>
              { polys.map((mainPoly, index) => {
                let poly = new window.google.maps.Polygon({
                  ...canvasPolygonOptions,
                  paths: mainPoly.getPath(),
                  center: mainPoly.center,
                })
                poly.m_id = mainPoly.m_id
                poly.marker_position = mainPoly.marker_position

                const widthSub = 420
                return <Col md={ 3 } className={ "pe-1 pb-3" } key={ index } style={{ minWidth: widthSub + 20 }} >
                          <CanvasMapView polys={ [poly] }
                                         size={ widthSub }
                                         reference={ setRef(`map-${ poly.m_id }`) } />
                       </Col>;
              }) }
            </Row>
          </BoxContainer>

          <BoxContainer title={ "Aktionen" }>
            <Row>
              <Col>
                <Button variant={ "success" } className={ "me-2" } onClick={ () => onAcceptAvatars() }>
                  Datei erstellen
                </Button>
                <Button variant={ "danger" } className={ "me-2" } onClick={ onCancel }>
                  Abbrechen
                </Button>
              </Col>
            </Row>
          </BoxContainer>
        </>
      )}
    </LoadingScreen>
  );
};
export default AvatarTuningView;
