import React, { useContext, useEffect, useReducer } from 'react';
import { Accordion, Button, Col, Row } from "react-bootstrap";
import { defaultStateMap, reducerMap } from "./components/reducer/reducerMap";
import { ModalContext } from "./components/modal/modalContext";
import {
  calculatePolygonArea,
  canvasPolygonOptions,
  containerStyle,
  getAvatarUrl,
  getLabel, MARKER_COLOR,
  POLY_START_ID, saveAsFile
} from "./components/helper";
import PolygonListElement from "./components/views/PolygonListElement";
import AddressSearchBox from "./components/views/AddressSearchBox";
import CustomMapView, { dataOptions } from "./components/views/CustomMapView";
import * as actionTypes from "./components/reducer/reducerTypes";
import AvatarSettingsModal from "./components/modal/AvatarSettingsModal";
import ExportImportModal from "./components/modal/ExportImportModal";
import { useSnackbar } from "notistack";
import AvatarTuningView from "./components/views/AvatarTuningView";
import { showSuccessBar } from "./components/ui/Snackbar";

let timer;

const MainContent = () => {

  const getSortedPolys = () => state.polys.sort((a, b) => a.m_id - b.m_id);

  const { enqueueSnackbar } = useSnackbar();
  const [state, dispatch] = useReducer(reducerMap, defaultStateMap);
  const [modalState, setModalState] = useContext(ModalContext);

  const onLoad = React.useCallback(function callback(map) {
    console.log("onLoad");
    dispatch({ type: actionTypes.SET_MAP, payload: map });
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    console.log("onUnmount");
    dispatch({ type: actionTypes.SET_MAP, payload: undefined });
  }, []);

  useEffect(() => {

    if (!state.showAvatars) {
      state.polys.map(poly => {
        poly.setMap(null)
        poly.setMap(state.map)

        poly.center_marker.setMap(null)
        poly.center_marker.setMap(state.map)
      })
    }
  }, [state.map]);

  function getAllPolys() {

    return getSortedPolys().map(rawPoly => {

      let poly = new window.google.maps.Polygon({
        ...canvasPolygonOptions,
        paths: rawPoly.getPath(),
        center: rawPoly.center,
      });
      poly.m_id = rawPoly.m_id;
      poly.center = rawPoly.center;
      poly.center_marker = rawPoly.center_marker;
      poly.custom_pos = rawPoly.custom_pos

      return poly;
    });
  }

  function initPoly(poly) {
    if (poly.m_id === undefined) {
      let polys = getSortedPolys();
      poly.m_id = polys.length > 0 ? polys.slice(-1)[0].m_id + 1 : POLY_START_ID;
    }
    calculatePolygonArea(state.map, poly, state.useLetters);
  }

  function polyChangeHandler(poly, action) {
    calculatePolygonArea(state.map, poly, state.useLetters);
    if (action === "dragend") {
      dispatch({ type: actionTypes.UPDATE_POLY, payload: poly });
    } else if (["set", "new", "removed"].includes(action)) {
      updateTimer(poly);
    } else {
      console.error("Unhandled Action:", action);
    }
  }

  function updateTimer(poly) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch({ type: actionTypes.UPDATE_POLY, payload: poly });
    }, 300);
  }

  function polyCreationHandler(poly) {

    initPoly(poly);

    console.log("polyCreationHandler", poly);
    dispatch({ type: actionTypes.ADD_POLY, payload: poly });

    if (!"custom_pos" in poly) {
      poly.custom_pos = false;
    }

    window.google.maps.event.addListener(poly, 'click', function () {
      polyChangeHandler(poly, "click");
    });

    poly.getPaths().forEach(function (path, index) {

      window.google.maps.event.addListener(path, 'insert_at', function () {
        polyChangeHandler(poly, "new");
      });

      window.google.maps.event.addListener(path, 'remove_at', function () {
        polyChangeHandler(poly, "removed");
      });

      window.google.maps.event.addListener(path, 'set_at', function () {
        polyChangeHandler(poly, "set");
      });

    });

    window.google.maps.event.addListener(poly, 'dragend', function () {
      polyChangeHandler(poly, "dragend");
    });

  }

  function onAddressChange(address) {
    let geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ 'address': address }, function (results, status) {
      if (status === 'OK') {
        let loc = results[0].geometry.location;
        dispatch({
          type: actionTypes.SET_VIEW_BOUNDS,
          payload: { zoom: 19, center: { lat: loc.lat(), lng: loc.lng() } }
        });
      } else {
        console.error('Geocode was not successful for the following reason: ' + status);
      }
    });
  }

  function refreshPolygons(polys, useLetters = undefined) {
    let polyId = POLY_START_ID; // Letter 'A' as int

    if (useLetters === undefined) {
      useLetters = state.useLetters;
    }

    polys.map(poly => {
      poly.m_id = polyId;
      poly.center_marker.setLabel({
        text: getLabel(poly.m_id, useLetters),
        color: MARKER_COLOR,
      });
      polyId += 1;
      return poly;
    });

    return polys;
  }

  function deletePolygon(poly) {
    const filtered_polys = state.polys.filter((p) => p.m_id !== poly.m_id);

    dispatch({ type: actionTypes.SET_POLYS, payload: refreshPolygons(filtered_polys) });

    poly.setMap(null);
    poly.center_marker.setMap(null);
  }

  function selectedLine(start, end) {
    if (start && end) {
      dispatch({ type: actionTypes.SET_LINES, payload: [start, end] });
    } else {
      dispatch({ type: actionTypes.SET_LINES, payload: [] });
    }
  }

  function clearMap() {
    state.polys.map(poly => deletePolygon(poly));
    dispatch({ type: actionTypes.CLEAR_MAP });
  }

  const callBackSettings = (data) => {
    if (data.useLetters !== state.useLetters) {
      dispatch({ type: actionTypes.SET_LABEL_TYPE, payload: data.useLetters });
      refreshPolygons(state.polys, data.useLetters);
    }

    if (data.fillAreas !== state.fillAreas) {
      dispatch({ type: actionTypes.SET_FILL_AREAS, payload: data.fillAreas });
      refreshPolygons(state.polys);
    }

  };

  const callBackExportImport = (data) => {

    console.log("callBackExportImport", data);

    clearMap();

    let obj = JSON.parse(data);
    dispatch({ type: actionTypes.IMPORT_DATA, payload: obj });

    obj.data.map(raw => {
      const poly = new window.google.maps.Polygon({
        ...dataOptions.polygonOptions,
        paths: raw.points,
      });
      poly.m_id = raw.id;
      poly.custom_pos = raw.custom_pos;
      poly.center = raw.center;
      poly.setMap(state.map);
      polyCreationHandler(poly);
    });

  };

  function callbackSwapPriority(event, id, direction) {
    event.stopPropagation();

    let poly_a = state.polys.findIndex(p => p.m_id === id);
    let poly_b = state.polys.findIndex(p => p.m_id === id + direction);
    if (poly_a > -1 && poly_b > -1) {
      let tmp = state.polys[poly_a];
      state.polys[poly_a] = state.polys[poly_b];
      state.polys[poly_b] = tmp;
      dispatch({ type: actionTypes.SET_POLYS, payload: state.polys });
      refreshPolygons(state.polys);
    }
  }

  const onAcceptAvatars = (data) => {
    data["addressSearch"] = state.addressSearch;
    data["center"] = state.center;
    data["size"] = state.size;
    data["zoom"] = state.zoom;
    data["useLetters"] = state.useLetters;
    data["fillAreas"] = state.fillAreas;

    let name = `facility_scanner_-_${ state.addressSearch.replace(/ /g, '-') }`;
    saveAsFile(JSON.stringify(data), name);
    showSuccessBar(enqueueSnackbar, "Datei wurde erfolgreich erzeugt");
    dispatch({ type: actionTypes.SHOW_AVATARS, payload: false });
  };

  return (
    <>
      <div className={ "version-infos" }>
        Version: { process.env.REACT_APP_VERSION }
      </div>

      <AvatarSettingsModal callBack={ callBackSettings } />
      <ExportImportModal callBack={ callBackExportImport } enqueueSnackbar={ enqueueSnackbar } />

      { state.showAvatars ? (
        <AvatarTuningView
          rawPolys={ getAllPolys() }
          onAccept={ onAcceptAvatars }
          onCancel={ () => dispatch({ type: actionTypes.SHOW_AVATARS, payload: false }) }
        />

      ) : (
        <>
          <Row>
            <AddressSearchBox changeListener={ onAddressChange }
                              callbackAddress={ (address) => dispatch({
                                type: actionTypes.SET_ADDRESS,
                                payload: address
                              }) }/>
          </Row>

          <Row>
            <Col md={ 9 }>
              <CustomMapView zoom={ state.zoom }
                             center={ state.center }
                             defaultCenter={ state.center }
                             lines={ state.lines }
                             onLoad={ onLoad }
                             onUnmount={ onUnmount }
                             creationHandler={ polyCreationHandler }/>
            </Col>

            { state.polys.length > 0 && (
              <Col md={ 3 } style={ { overflowY: "scroll", ...containerStyle } }>
                <div className={ "ps-3 fs-4 text" }>
                  Gesamtfläche: { state.polys.reduce((sum, value) => sum + parseFloat(value.area), 0).toFixed(2) }qm
                </div>

                <Accordion defaultActiveKey="0">
                  { getSortedPolys().map(poly => {
                    return <PolygonListElement key={ poly.m_id }
                                               poly={ poly }
                                               state={ state }
                                               useLetters={ state.useLetters }
                                               handleDeletePolygon={ deletePolygon }
                                               callbackSwapPriority={ callbackSwapPriority }
                                               url={ getAvatarUrl(state.map, state.size, state.useLetters, [poly]) }
                                               selectedLine={ selectedLine }/>;
                  })}
                </Accordion>
              </Col>
            ) }

          </Row>

          <Row className={ "w-100 mt-3" }>
            <Col md={ 2 }>
              <Button disabled={ state.polys.length === 0 } className="w-100 px-2"
                      onClick={ () => dispatch({ type: actionTypes.SHOW_AVATARS, payload: true }) }>
                Für Facility-Scanner aufbereiten
              </Button>
            </Col>

            <Col md={ 2 }>
              <Button variant="info" className="w-100 px-2"
                      onClick={ () => setModalState({
                        ...modalState,
                        modalExportImport: true,
                        state: state
                      }) }>Export/Import</Button>
            </Col>

            <Col md={ 2 }>
              <Button variant="info"
                      className="w-100 px-2"
                      onClick={ () => setModalState({ ...modalState, modalAvatarSettings: true }) }>
                Einstellungen
              </Button>
            </Col>
            <Col md={ 2 }>
              <Button variant="danger"
                      className="w-100 px-2"
                      onClick={ () => clearMap() }>
                Alle Flächen löschen
              </Button>
            </Col>
          </Row>
        </>
      ) }
    </>
  );
};

export default MainContent;
