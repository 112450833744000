import React from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { calculatePolygonArea, libs } from "../helper";

function CanvasMapView({ polys, reference, markerMoveListener = () => {}, size = 420, padding = 50 }) {

  const onLoad = React.useCallback(function callback(map) {

    let isMain = polys.length > 1
    let bounds = new window.google.maps.LatLngBounds();

    polys.map(poly => {
      poly.getPath().forEach(element => {
        bounds.extend(element);
      });
      poly.setMap(map);
      calculatePolygonArea(map, poly, false, isMain, markerMoveListener);
    });

    map.fitBounds(bounds, padding);
    map["polys"] = polys

  }, []);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY,
    libraries: libs,
  });

  const options = {
    mapTypeId: "satellite",
    disableDefaultUI: true,
    zoomControl: false,
    mapTypeControl: false,
    scaleControl: false,
    rotateControl: false,
    panControl: false,
    tilt: 0
  };

  return isLoaded ? (

    <GoogleMap
      ref={ reference }
      options={ options }
      mapContainerStyle={ {
        width: `${ size }px`,
        height: `${ size }px`
      } }
      onLoad={ onLoad }
      />

  ) : <></>;
}

export default React.memo(CanvasMapView);
