import { Button, Col, Row, Accordion } from "react-bootstrap";
import React, { useState } from "react";
import { getLabel } from "../helper";
import { FormControlLabel, FormGroup, Switch } from "@material-ui/core";

const PolygonListElement = ({ poly, state, url, handleDeletePolygon, callbackSwapPriority, selectedLine }) => {

  let polyCount = poly.points.length;
  let lines = [];
  const [draggable, setDraggable] = useState(poly.custom_pos);


  poly.points.map((coord, index) => {
    let next;
    if (index !== polyCount - 1) {
      next = poly.points[index + 1];
    } else {
      next = poly.points[0];
    }

    let dist = window.google.maps.geometry.spherical.computeDistanceBetween(coord, next);
    lines.push({ coord: coord, next: next, distance: dist });

    return true;
  });

  let sum = 0;
  lines.forEach((line) => {
    sum += line.distance;
  });

  let all_lines = [];


  return (
    <Accordion.Item eventKey={ poly.m_id }>
      <Accordion.Header>
        <i className="bi bi-arrow-up custom-hover" onClick={ (e) => callbackSwapPriority(e, poly.m_id, -1) }/>
        <i className="bi bi-arrow-down custom-hover" onClick={ (e) => callbackSwapPriority(e, poly.m_id, 1) }/>
        Fläche #{ getLabel(poly.m_id, state.useLetters) } ({ poly.area }qm)
      </Accordion.Header>
      <Accordion.Body>

        <Row className={ "pb-4" }>
          <Col md={ 6 }>
            Gesamtumlauf: { sum.toFixed(2) }m
          </Col>
          <Col>
            <Button className={ "float-end bi bi-trash-fill" } variant={ "danger" }
                    onClick={ () => handleDeletePolygon(poly) }/>
            <Button className={ "float-end bi bi-pencil me-2" } onClick={ () => window.open(url, '_blank').focus() }/>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup row>
              <FormControlLabel
                label={ `Label ist verschiebbar` } name="customPosition"
                control={ <Switch checked={ draggable } onChange={ (e) => {
                  const checked = e.target.checked;
                  setDraggable(checked);
                  poly.custom_pos = checked;
                } }/> }
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          Teilstrecken:
          <div className="list-group" onMouseOut={ () => selectedLine(null, null) }>
            {
              lines.map((line, index) => {
                let distance = line.distance.toFixed(2);
                all_lines.push(distance);
                return <button key={ index } type="button" onMouseOver={ () => selectedLine(line.coord, line.next) }
                               className="list-group-item list-group-item-action">
                  { distance }m
                </button>;

              })
            }
          </div>
        </Row>

        <Row>
          @Copy: <input readOnly={ true } className={ "w-100" } value={ all_lines.join(", ") }/>
        </Row>

      </Accordion.Body>
    </Accordion.Item>

  );
};

export default React.memo(PolygonListElement);

// export default PolygonListElement;
