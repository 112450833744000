import React from 'react';
import { SnackbarProvider } from "notistack";
import { ModalProvider } from "./components/modal/modalContext";
import MainContent from "./MainContent";
import { Container } from "react-bootstrap";

const App = () => {

  return (
    <SnackbarProvider maxSnack={ 5 } anchorOrigin={ { vertical: 'top', horizontal: 'right' } }>
      <ModalProvider>
        <Container fluid>
          <MainContent/>
        </Container>
      </ModalProvider>
    </SnackbarProvider>
  );

};

export default App;
