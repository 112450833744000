import React, { useContext, useReducer } from 'react';
import { Button, Form, Modal } from "react-bootstrap";
import { ModalContext } from "./modalContext";
import { defaultStateMap, reducerMap } from "../reducer/reducerMap";

import { useForm } from "react-hook-form";
import { FormControlLabel, FormGroup, Switch } from "@material-ui/core";

import * as actionTypes from "../reducer/reducerTypes";


const AvatarSettingsModal = ({ callBack }) => {

  const { handleSubmit } = useForm();
  const [show, setShow] = useContext(ModalContext);
  const [state, dispatch] = useReducer(reducerMap, defaultStateMap);


  const onSubmit = () => {
    handleClose();
    callBack(state);
  };

  const handleClose = () => {
    setShow((show) => ( { ...show, modalAvatarSettings: false } ));
  };

  return (
    <Form>
      <Modal show={ show.modalAvatarSettings } onHide={ handleClose }>
        <Modal.Header>
          <Modal.Title>Einstellungen</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <FormGroup row>
            <FormControlLabel
              label="Labels nutzen Buchstaben" name="useLetters"
              control={ <Switch checked={ state.useLetters } onChange={ (e) => {
                dispatch({ type: actionTypes.SET_LABEL_TYPE, payload: e.target.checked });
              } }/> }
            />
          </FormGroup>

          {/*<FormGroup row>*/ }
          {/*  <FormControlLabel*/ }
          {/*   label="Flächen werden gefüllt" name="fillAreas"*/ }
          {/*   control={ <Switch checked={ state.fillAreas } onChange={ (e) =>*/ }
          {/*     { dispatch({ type: actionTypes.SET_FILL_AREAS, payload: e.target.checked }); }} /> }*/ }
          {/*  />*/ }
          {/*</FormGroup>*/ }

        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={ handleClose }>Schließen</Button>
          <Button variant="success" onClick={ handleSubmit(onSubmit) }>Speichern</Button>
        </Modal.Footer>
      </Modal>
    </Form>
  );

};

export default AvatarSettingsModal;
